/**
 * @license
 * Copyright 2024 Google LLC.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Common styles for new experiments pages. */
.new-exp-input-fields {
  margin: 1em;
  min-width: 500px;
  max-width: 800px;
}

.new-exp-text-style {
  font-size: 16px;
}

.new-exp-form-field {
  width: 100%;
  display: block;
  margin: 0.8em;
}

.input-btn {
  display: block;
  margin: 1em;
}

/* Common styles for pages allowing users to view existing experiments. */
.view-experiment-field {
  width: 50%;
  margin: 1em;
  min-width: 750px;
}

.view-experiment-last-field {
  width: 50%;
  margin: 1em;
  margin-bottom: 2em;
  min-width: 750px;
}

.wrap-to-fit-card {
  padding-left: 1em;
  overflow-wrap: anywhere;
}

.card-subtitle-one-line {
  text-wrap: nowrap;
}

.edit-exp-details-btn {
  display:inline-flex;
}

.exp-details-title {
  padding-top: 8px;
}

.edit-exp-details-save-btn {
  min-width: 575px;
  margin-bottom: 1em;
  margin-left: 1em;
}

.edit-exp-details-input {
  width:50%;
  min-width:750px;
}


//TODO() fine tune or remove.
.view-exp-cards {
  margin: 1em;
  min-width: 150px;
  max-width: 800px;
}

.view-exp-card-content {
  margin-bottom: 0.5em;
}

.error-message {
  color: #f44336;
  font-weight: 450;
  margin-top: 1em;
  margin-bottom: 1em;
}

.view-results-buttons {
  margin-top: 0.5em;
}

.view-result-buttons {
  margin: 0.5em;
  min-width: 575px;
}

.pending-results-splinner {
  margin: 1em auto;
}

.view-exp {
  margin: 1em;
}

.view-results {
  margin-top: 1em;
}

.view-experiment-parameters {
  margin-top: 2em;
}

/* Common styles for the list of experiments/landing page. */

.experiments_list {
  margin-left: 1em;
  margin-right: 1em;
}

.view-exp-file-field {
  width: 100%;
  margin: 1em 1em 1em 0px;
}

/* Common styles for pages allowing users to view the experiment results. */
.view-result-entry {
  margin: 1em;
}

.view-result-container {
  margin-top: 2em;
  margin-bottom: 2em;
  min-width: 660px;
}

.cursor-pointer {
  cursor: pointer;
}

.budget-simulation-table {
  margin: 2em 1em 1em;
}

.budget-column-text {
  color: #ff4081;
}

.left-button {
  margin-right: 2em;
}

.file-preview-container {
  margin: 0 0 2em 2em;
}

.selected-file-name {
  display: inline-block;
  margin-left: 1em;
}

.selected-file-close {
  display: inline-block;
  font-size: 18px;
}

.download-button {
  float: right;
  margin-top: 1em;
}

.causal-impact-summary-table {
  table-layout: fixed;
  width: 100%;
}

.causal-impact-summary-table th {
  text-align: left;
}

.causal-impact-summary-text {
  margin-top: 2em;
}

.causal-impact-summary-text p{
  line-height: 1em;
}

table.mat-mdc-table {
  white-space: nowrap;
}

.multi-line-tooltip {
  white-space: pre-line;
}

// stylelint-disable-next-line --  Required for resizing tooltip pop-up.
.max-width-tooltip .mdc-tooltip__surface {
  max-width: 700px;
}

// stylelint-disable-next-line --  Required for resizing tooltip pop-up.
.unset-max-width-tooltip .mdc-tooltip__surface {
  max-width: unset;
}